import React, { useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { register, resetPassword, verifyResetRequest } from '../../Api/UserApi'; // You might need to create this API method
import { reset } from 'ol/transform';

export const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [verifying, setVerifying] = useState(true);
    const [resetToken, setResetToken] = useState('');

    useEffect(() => {
        const resetToken = window.location.pathname.split("/")[2];
        verifyResetRequest(resetToken).then((result) => {
            setResetToken(resetToken);
            setVerifying(false);
        }).catch((error) => {
            window.location.href = '/login';
        });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Basic password match validation
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        setLoading(true);
        setError('');

        resetPassword(password, resetToken).then((response) => {
            console.log('response', response);

            // Navigate to the login page after successful registration or take any other desired action
            window.location.href = '/login?newPassword=true';

        }).catch((error) => {
            const responseCode = error.response ? error.response.status : null;
            if (responseCode === 400) {
                setError(error.response.data); // Assuming 400 contains an error message from your API
            } else {
                setError('An error occurred');
            }

            setLoading(false);
        });
    }

    return (
        <Container style={{ maxWidth: '400px', marginTop: '50px' }}>
            <h2>Reset PinPoint Password</h2>
            <Form>
                <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </Form.Group>

                <Form.Group controlId="confirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                </Form.Group>
                <br />
                <Button variant="primary" type="submit" onClick={handleSubmit} disabled={loading || verifying}>
                    {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                    {!loading && 'Reset'}
                </Button>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </Form>
        </Container>
    );
};
