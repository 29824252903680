import { Container, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import this
import { CurrentUser } from "../../Helpers/CurrentUser";
import "./PinPointNavbar.css";

  function PinPointNavbar() {
    const navigate = useNavigate(); // Get the navigate function

    const handleLogout = () => {
      CurrentUser.removeToken();
      navigate("/login"); // Navigate to login page
    }

    return (
      <Navbar expand="lg" className="bg-body-tertiary navbar">
        <Container>
          <Navbar.Brand href="/"><img src="/icons8-point-stickers-32.png" />
            PinPoint
            {process.env.REACT_APP_BUILD !== "" && <span className="environment"> ({process.env.REACT_APP_BUILD})</span>}
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {/* <Nav.Link href="sessions" style={{color:'white'}}>Sessions</Nav.Link> */}
              <Nav.Link href="Municipalities" style={{ color: 'white' }}>Municipalities</Nav.Link>
              <Nav.Link href="Sessions" style={{ color: 'white' }}>Manage Sessions</Nav.Link>
              <Nav.Link style={{ color: 'white' }} onClick={handleLogout}>Logout</Nav.Link> {/* Use the handleLogout function */}
              {/* Rest of your code */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }

export default PinPointNavbar;
