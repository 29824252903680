import axios from "axios";

export const login = async (email, password) => {
	return axios
		.post(process.env.REACT_APP_SERVER_URL + "/user/login", { email, password });
}

export const register = async (email, password) => {
	return axios
		.post(process.env.REACT_APP_SERVER_URL + "/user/create", { email, password });
}

export const forgottenPassword = async (email) => {
	return axios.post(process.env.REACT_APP_SERVER_URL + "/user/forgotten", { email });
}

export const verifyResetRequest = async (resetToken) => {
	return axios.get(process.env.REACT_APP_SERVER_URL + "/user/verifyresetrequest?resetToken=" + resetToken);	
}

export const resetPassword = async (password, resetToken) => {
	return axios.post(process.env.REACT_APP_SERVER_URL + "/user/ResetPassword",{password, resetToken})
}