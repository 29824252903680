import React, { useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { forgottenPassword, login } from "../../Api/UserApi";
import { CurrentUser } from "../../Helpers/CurrentUser";

export const ForgottenPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submit", email);
    setLoading(true);
    setError("");

    forgottenPassword(email)
      .then((response) => {
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        const responseCode = error.response ? error.response.status : null;
        if (responseCode === 401) {
          setError(error.response.data);
        } else {
          setError("An error occurred");
        }

        setLoading(false);
      });
  };

  return (
    <Container style={{ maxWidth: "400px", marginTop: "50px" }}>
      <h2>Forgotten Password</h2>
      <p>Fill out this form and you will recieve a link shortly to reset your password.</p>
      <Form>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Form.Group>
        <br />
        {success && <p class="text-success">Please check your inbox for a link to reset your password. <a href="/login">Click here to return to login.</a></p>}
        <Button
          variant="primary"
          type="submit"
          onClick={handleSubmit}
          disabled={loading || success}
          style={{ marginRight: "10px" }}
        >
          {loading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          {!loading && "Submit"}
        </Button>
        {error && <p style={{ color: "red" }}>{error}</p>}
      </Form>
    </Container>
  );
};
