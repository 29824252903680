import { Feature } from "ol"; import { createEmpty, extend } from 'ol/extent';
import olView from "ol/View";
import { LineString, Point } from "ol/geom.js";
import { fromLonLat } from "ol/proj";
import { Fill, Icon, Stroke, Style } from "ol/style.js";
import CircleStyle from "ol/style/Circle.js";
import { useEffect, useMemo, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { getSharedPingSession } from "../../Api/PingSessionApi.js";
import { SessionsMapControlbar } from "../../Components/SessionsMapControlsBar.js";
import { useInterval } from "../../Hooks/UseInterval.js";
import Controls from "../../MapComponents/Controls/Controls";
import FullScreenControl from "../../MapComponents/Controls/FullScreenControl";
import Layers from "../../MapComponents/Layers/Layers";
import TileLayer from "../../MapComponents/Layers/TileLayer";
import VectorLayer from "../../MapComponents/Layers/VectorLayer";
import Map from "../../MapComponents/Map/Map";
import Overlays from "../../MapComponents/Overlays/Overlays.js";
import PinPointInfoOverlay from "../../MapComponents/Overlays/PinPointInfoOverlay.js";
import MyVectorSource from "../../MapComponents/Source/VectorSource";
import osm from "../../MapComponents/Source/osm";
import "../SessionViewer/SessionViewer.css";

export const SharedSessionViewer = () => {
    const [sharedSessionId, setSharedSessionId] = useState(null);
    const [autoUpdate, setAutoUpdate] = useState(true);
    const [autoCenter, setAutoCenter] = useState(true);
    const [infoOverlayFeature, setInfoOverlayFeature] = useState(null);
    const [lineFeatures, setLineFeatures] = useState([]);
    const [autoDrawLines, setAutoDrawLines] = useState(false);
    const [performanceDraw, setPerformanceDraw] = useState(true);
    const [features, setFeatures] = useState([]);
    const [displayPoints, setDisplayPoints] = useState(true);
    const [username, setUsername] = useState("");
    const [sessionEnded, setSessionEnded] = useState(false);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [location, setLocation] = useState(null);
    const [hideTopBar, setHideTopBar] = useState(true);
    const [userMovedMap, setUserMovedMap] = useState(false);
    const [focusBoth, setFocusBoth] = useState(true);

    const view = useMemo(
        () =>
            new olView({
                center: fromLonLat([-0.38466814, 51.404773]),
                zoom: 16,

            }),
        []
    );

    const iconStyle = useMemo(() => {
        return new Style({
            image: new CircleStyle({
                fill: new Fill({ color: 'rgba(200, 35, 35, 0.55)' }),
                stroke: new Stroke({ color: 'rgba(200, 35, 35,0.8)', width: 2 }),
                points: 6,
                radius: 22
            }),
        });
    }, []);

    const currentLocationPointStyle = useMemo(() => {
        return new Style({
            image: new CircleStyle({
                fill: new Fill({ color: 'rgba(35, 200, 35, 0.55)' }),
                stroke: new Stroke({ color: 'rgba(35, 200, 35,0.8)', width: 2 }),
                points: 4,
                radius: 16
            }),
        });
    }, []);

    const vectorStyle = useMemo(() => {
        // Cache for point style
        const pointStyleCache = {};
        // Cache for line style
        const lineStyleCache = {};

        return (feature) => {
            if (feature.getGeometry() instanceof Point) {
                let radius = 6;

                // Check if we've cached this style before
                if (pointStyleCache[radius]) {
                    return pointStyleCache[radius];
                }

                const newStyle = new Style({
                    image: new CircleStyle({
                        fill: new Fill({ color: 'rgba(180, 58, 58, 0.55)' }),
                        stroke: new Stroke({ color: 'rgba(180, 58, 58,0.8)', width: 1 }),
                        points: 4,
                        radius: 3
                    }),
                });

                pointStyleCache[radius] = newStyle;
                return newStyle;
            } else if (feature.getGeometry() instanceof LineString) {
                let speed = feature.get("speed");
                let color = "rgba(0,0,0,1)";
                if (speed !== null) {
                    speed = speed / 1.609;
                    const clampedSpeed = Math.min(Math.max(speed, 0), 70);
                    const redComponent = Math.round((clampedSpeed / 70) * 255);
                    const blueComponent = Math.round(255 - (clampedSpeed / 70) * 255);
                    color = `rgba(${redComponent}, 0, ${blueComponent}, 1)`;
                }

                // Check if we've cached this style before
                if (lineStyleCache[color]) {
                    return lineStyleCache[color];
                }

                // Create and cache the style
                const newStyle = new Style({
                    stroke: new Stroke({
                        color: color,
                        width: 5,
                    }),
                });
                lineStyleCache[color] = newStyle;
                return newStyle;
            }
        };
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has("sharedSessionId")) {
            setSharedSessionId(urlParams.get("sharedSessionId"));

        }

    }, [window.location.search]);

    useEffect(() => {
        getLocation();
    }, []);

    useEffect(() => {
        if (sharedSessionId) {
            loadPings(true);
        }
    }, [sharedSessionId]);

    useInterval(
        () => {
            loadPings(autoCenter);
        },
        autoUpdate && !sessionEnded ? 5000 : null
    );

    useInterval(() => {
        getLocation();
    }, 10000);

    const getLocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    // Create a Ol point I can draw on the ol map below
                    const olPoint = new Point(fromLonLat([longitude, latitude]));

                    // Create a new feature with the point geometry
                    const pointFeature = new Feature(olPoint);
                    setLocation({ latitude, longitude, pointFeature });
                },
                (error) => {
                    console.error("Error getting location:", error.message);
                }
            );
        } else {
            console.log("Geolocation is not available in this browser.");
        }
    };

    useEffect(() => {
        // Only want this to happen if we are not already auto refreshing
        if (sessionEnded) {
            loadPings(true);
        }
    }, [performanceDraw]);

    const loadPings = async (centerLatest = false, centerAll = false) => {
        console.log("Center all", centerAll);
        getSharedPingSession(sharedSessionId).then((pingSession) => {
            const builtFeatures = pingSession.pings.map((ping, index) => {
                let point = new Point(
                    fromLonLat([
                        ping.position.coordinates[1],
                        ping.position.coordinates[0],
                    ])
                );
                let feature = new Feature(point);
                feature.set("id", ping.pingId);
                feature.set("createDate", ping.createDate);
                feature.set("pingSessionId", ping.pingSessionId);
                feature.set("speed", ping.speedKilometresPerHour);
                feature.set("acceleration", ping.accelerationMetresPerSecond);
                feature.set("index", index);
                feature.set("totalPings", pingSession.pings.length);
                return feature;
            });

            setLastUpdated(pingSession.pings[0].createDate);

            setUsername(pingSession.userEmail);
            setSessionEnded(pingSession.endDateDisplay);

            if (performanceDraw) {
                setFeatures(builtFeatures.slice(0, 1));
            } else {
                setFeatures(builtFeatures);
            }

            if (centerLatest && builtFeatures.length > 0) {
                console.log("Center latest");
                const latestFeature = builtFeatures[0];
                centerView(latestFeature);
            } else if (centerAll && builtFeatures.length > 0) {
                console.log("Center all");
                let minX = Infinity,
                    minY = Infinity,
                    maxX = -Infinity,
                    maxY = -Infinity;

                for (let i = 0; i < builtFeatures.length; i++) {
                    const f = builtFeatures[i];
                    const cords = f.getGeometry().flatCoordinates;
                    const x = cords[0];
                    const y = cords[1];
                    if (x < minX) minX = x;
                    if (y < minY) minY = y;
                    if (x > maxX) maxX = x;
                    if (y > maxY) maxY = y;
                }

                const buffer = 2000;
                const bufferedExtent = [
                    minX - buffer,
                    minY - buffer,
                    maxX + buffer,
                    maxY + buffer,
                ];
                // Zoom to the extent
                view.fit(bufferedExtent, { duration: 1000 });
            }
        });
    };

    const centerView = (latestFeature) => {
        if (location === null || focusBoth == false) {
            view.fit(latestFeature.getGeometry().getExtent(), {
                padding: [50, 50, 50, 50],
                duration: 200,
                maxZoom: 18
            }); 
        } else {
            // Create an extent that includes both features
            const extent = createEmpty();
            extend(extent, latestFeature.getGeometry().getExtent());
            extend(extent, location.pointFeature.getGeometry().getExtent());

            // Fit the view to show both features
            view.fit(extent, {
                padding: [50, 50, 50, 50],
                duration: 200,
                maxZoom: 18
            });
        }
    }

    const handlePointerClick = (e) => {
        const map = e.map;
        const features = map.getFeaturesAtPixel(e.pixel, {
            hitTolerance: 4,
        });
        const pointFeatures = features.filter(
            (feature) => feature.getGeometry() instanceof Point
        );

        if (pointFeatures.length > 0) {
            setInfoOverlayFeature(pointFeatures[0]);
        } else {
            setInfoOverlayFeature(null);
        }
    };

    const handleMapMove = (e) => {
        if (e.dragging === true) {
            console.log("Map moved");
            console.log(e);
            setUserMovedMap(true);
            setAutoCenter(false);
        }
    };

    const handleNextFeatureClick = (feature, direction) => {
        // find index of existing feature by Id
        const index = features.findIndex((f) => f.get("id") === feature.get("id"));

        const nextFeature = features[index + direction];
        setInfoOverlayFeature(nextFeature);

        view.setCenter(nextFeature.getGeometry().getCoordinates());
    };

    const handleFocusChange = (event) => {
        const newFocusBoth = event.target.value === 'both';
        setFocusBoth(newFocusBoth); 
      };

    return (
        <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
            <div hidden={hideTopBar}>
                <SessionsMapControlbar
                    autoUpdate={autoUpdate}
                    setAutoUpdate={setAutoUpdate}
                    autoCenter={autoCenter}
                    setAutoCenter={setAutoCenter}
                    performanceDraw={performanceDraw}
                    setPerformanceDraw={setPerformanceDraw}
                    displayPoints={displayPoints}
                    setDisplayPoints={setDisplayPoints}
                    autoDrawLines={autoDrawLines}
                    setAutoDrawLines={setAutoDrawLines}
                />
                {/* <Button variant="primary" style={{ position: "absolute", bottom: 10, right: 10, zIndex: 2000 }} onClick={() => window.location.href = "/"}>Back to home</Button> */}
                <div
                    style={{
                        position: "relative",
                        flexDirection: "row",
                        padding: "0.5rem",
                        zIndex: 50,
                        backgroundColor: "#393040",
                        display: "flex",
                        color: 'white'
                    }}
                >
                    <Container style={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex', flexWrap: 'wrap' }}>
                        <p>You are currently viewing a shared session from: {username}</p>
                        {!sessionEnded && <p>Last updated: {lastUpdated}</p>}
                        {sessionEnded && <p style={{ fontWeight: "bold", color: "red" }}>This session was ended by the user at {sessionEnded}</p>}
                    </Container>
                    <Button variant="success" onClick={() => { setHideTopBar(true); }}>Hide</Button>
                </div>
            </div>
            <div hidden={!hideTopBar}>
                <div style={{ position: "absolute", top: 10, left: 10, zIndex: 2000, backgroundColor: "indianRed", padding: 6, borderRadius:4 }} >
                    <input type="radio" checked={!focusBoth} value="driver" onChange={handleFocusChange} />&nbsp;<label>Driver</label><br />
                    <input type="radio" checked={focusBoth} value="both" onChange={handleFocusChange} />&nbsp;<label>Driver and You</label>
                </div>
                <Button style={{ position: "absolute", top: 10, right: 10, zIndex: 2000 }} variant="success" onClick={() => { setHideTopBar(false); }}>Show Info</Button>
                <Button hidden={autoCenter} style={{ position: "absolute", bottom: 10, right: 10, zIndex: 2000 }} variant="danger" onClick={() => { setAutoCenter(true); setUserMovedMap(false); centerView(features[0]); }}>Recenter</Button>
            </div>
            <div className="sessionViewMapContainer">
                <Map view={view} onClick={handlePointerClick} onPointerMove={handleMapMove}>
                    <Layers>
                        <TileLayer source={osm()} zIndex={0} />
                        <VectorLayer style={iconStyle} zIndex={4}>
                            <MyVectorSource features={features.filter(x => x.get("index") == 0)} />
                        </VectorLayer>
                        <VectorLayer style={vectorStyle} zIndex={3}>
                            <MyVectorSource features={lineFeatures} />
                        </VectorLayer>
                        <VectorLayer style={vectorStyle} zIndex={2}>
                            <MyVectorSource features={features} display={displayPoints} />
                        </VectorLayer>
                        <VectorLayer style={currentLocationPointStyle} zIndex={2}>
                            <MyVectorSource features={location ? [location.pointFeature] : []} />
                        </VectorLayer>
                    </Layers>
                    <Controls>
                        <FullScreenControl />
                    </Controls>
                    <Overlays>
                        <PinPointInfoOverlay
                            feature={infoOverlayFeature}
                            onNextFeatureClick={handleNextFeatureClick}
                        />
                    </Overlays>
                </Map>
            </div>
        </div>
    );
};